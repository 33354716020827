import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as sessionService from 'service/session';
import * as userService from 'service/user';
import config from 'config';
import initSidebar from './init-sidebar';
import initNavigation from './init-navigation';
import fixedSidebar from './fixed-sidebar'
import Analytics from 'component/analytics';
import './style.scss';
import placeholderImage from 'vendor/limitless/images/placeholders/placeholder.jpg';

const mapStateToProps = state => {
  return {
    username: state.currentUserInfo.username,
    nickname: state.currentUserInfo.nickname,
    email: state.currentUserInfo.email,
  };
};

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsConfig: {
        analyticsList: [
          { type: 'baidu', id: config.BAIDU_ANALYTICS_ID },
        ],
      },
    }
  }

  componentDidMount() {
    initSidebar()
    initNavigation()
    fixedSidebar()
  }

  logout = async () => {
    await sessionService.logout();
    await userService.destroyCurrentUserInfo();
    this.props.history.push('/login');
  }

  render() {
    const { analyticsConfig } = this.state;

    return (
      <div id="app" className="navbar-top">
        <Analytics config={analyticsConfig} />
        <div className="navbar navbar-expand-md navbar-light fixed-top">
          <div className="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
            <div className="navbar-brand navbar-brand-md">
              <span className="navbar-brand__title text-white font-weight-bold"><i className="icon-cube3 mr-2"></i> ENDLESS</span>
            </div>

            <div className="navbar-brand navbar-brand-xs">
              <i className="icon-cube3"></i>
            </div>
          </div>

          <div className="d-flex flex-1 d-md-none">
            <div className="navbar-brand mr-auto">
              <a href="index.html" className="d-inline-block">
                <img src="../../../../global_assets/images/logo_dark.png" alt="" />
              </a>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
              <i className="icon-tree5"></i>
            </button>

            <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
              <i className="icon-paragraph-justify3"></i>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbar-mobile">
            <ul className="navbar-nav mr-md-auto">
              <li className="nav-items">
                <a href="/" className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                  <i className="icon-paragraph-justify3"></i>
                </a>
              </li>

              <li className="nav-items">
                <a href="//blog.wardchan.com" className="navbar-nav-link sidebar-control d-none d-md-block">
                  <i className="icon-home2"></i>
                  <span className="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
                </a>
              </li>
            </ul>

            <ul className="navbar-nav">
              <li className="nav-item dropdown dropdown-user">
                <a href="/" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                  <img src={placeholderImage} className="rounded-circle mr-2" height="34" alt="" />
                  <span>{this.props.nickname}</span>
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                  <a href="/" className="dropdown-item"><i className="icon-cog5"></i> Account settings</a>
                  <span onClick={this.logout} className="dropdown-item"><i className="icon-switch2"></i> Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="page-content">
          <div className="sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md">
            <div className="sidebar-mobile-toggler text-center">
              <a href="/" className="sidebar-mobile-main-toggle">
                <i className="icon-arrow-left8"></i>
              </a>
              Navigation
              <a href="/" className="sidebar-mobile-expand">
                <i className="icon-screen-full"></i>
                <i className="icon-screen-normal"></i>
              </a>
            </div>
            <div className="sidebar-content">
              <div className="sidebar-user">
                <div className="card-body">
                  <div className="media">
                    <div className="mr-3">
                      <a href="/">
                        <img src={placeholderImage} width="38" height="38" className="rounded-circle" alt="" />
                      </a>
                    </div>

                    <div className="media-body">
                      <div className="media-title font-weight-semibold">{this.props.nickname}</div>
                      <div className="font-size-xs opacity-50">
                        <i className="icon-mail5"></i> &nbsp;{this.props.email}
                      </div>
                    </div>

                    <div className="ml-3 align-self-center">
                    <a href="/" className="text-white"><i className="icon-cog3"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-sidebar-mobile">
                <ul className="nav nav-sidebar" data-nav-type="accordion">
                  <div className="nav-item-header">
                    <div className="text-uppercase font-size-xs line-height-xs">Blog</div>
                    <i className="icon-menu"></i>
                  </div>
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link"><i className="icon-stats-bars"></i> <span>Dashboard</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/users" className="nav-link"><i className="icon-people"></i> <span>Users</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/posts" className="nav-link"><i className="icon-stack"></i> <span>Posts</span></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            {this.props.children}

            <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                  <i className="icon-unfold mr-2"></i> Footer
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbar-footer">
                <div className="navbar-text">
                  &copy; 2018 - 2019. <a href="/">Endless</a> by <a href="//wardchan.com" target="_blank" rel="noopener noreferrer">Ward Chan</a>
                </div>
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item">
                    <a href="//github.com/wardchan/blog.wardchan.com" className="navbar-nav-link" target="_blank" rel="noopener noreferrer">
                      <i className="icon-github mr-2"></i> Github
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps)(withRouter(Layout));