import $ from 'jquery';
import Bootbox from 'bootbox';

export const blockUI = $el => {
  $($el).block({
    message: '<i class="icon-spinner2 spinner"></i>',
    overlayCSS: {
      backgroundColor: '#fff',
      opacity: 0.8,
      cursor: 'wait',
      'box-shadow': '0 0 0 1px #ddd'
    },
    css: {
      border: 0,
      padding: 0,
      backgroundColor: 'none'
    }
  });
};

export const unBlockUI = $el => {
  $($el).unblock();
};

export const handleError = (error, { setFieldError }) => {
  const resp = error.data;
  switch (error.status) {
    case 500:
      alert(resp.message);
      break;
    case 422:
      resp.errors.forEach(e => setFieldError(e.path, e.message));
      break;
    default:
      break;
  }
};

export const bootbox = {
  confirm(option) {
    return new Promise(resolve => {
      Bootbox.confirm({
        ...option,
        callback(result) {
          resolve(result);
        },
        buttons: {
          confirm: {
            label: 'Yes',
            className: 'btn-primary'
          },
          cancel: {
            label: 'Cancel',
            className: 'btn-link'
          }
        },
      });
    });
  },
};