import axios from 'axios';

import config from 'config';
import store from 'service/store';
import * as action from './action';

export const requestStatistic = async () => {
  await store.dispatch(action.requestStatistic());
  const statistic = await axios.get(config.API.DASHBOARD_STATISTIC_URL);
  await store.dispatch(action.receiveStatistic(statistic));
  return statistic;
};