import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export default class DatePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }

  componentDidMount() {
    $(this.el).AnyTime_noPicker().AnyTime_picker();
    $(this.el).change(() => this.props.onChange($(this.el).val()));
  }

  render() {
    const { name, value } = this.props;
    return (
      <input
        type="text"
        className="form-control"
        id={name}
        name={name}
        defaultValue={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : ''}
        ref={el => this.el = el}
      />
    );
  }
}