import axios from 'axios';

import config from 'config';
import store from 'service/store';
import * as action from './action';

export const requestPostListInfo = async (option) => {
  await store.dispatch(action.requestPostListInfo());
  const postListInfo = await axios.get(config.API.POSTS_URL, option);
  postListInfo.skip = option.params.skip || 0;
  await store.dispatch(action.receivePostListInfo(postListInfo));
  return postListInfo;
};

export const requestPost = async (staticURL) => {
  const post = await axios.get(`${config.API.POSTS_URL}/${staticURL}`);
  return post;
};

export const updatePost = async (post) => {
  await axios.put(`${config.API.POSTS_URL}/${post._id}`, post);
}

export const createPost = async (post) => {
  await axios.post(config.API.POSTS_URL, post);
}

export const deletePost = async (postID) => {
  await axios.delete(`${config.API.POSTS_URL}/${postID}`);
};