import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import store from 'service/store';
import Layout from 'component/layout';
import Login from 'container/login';
import App from 'container/app';
import Dashboard from 'container/dashboard';
import UserList from 'container/user/list';
import UserDetail from 'container/user/detail';
import PostList from 'container/post/list';
import PostDetail from 'container/post/detail';

export default class SiteRouter extends React.Component {
  render() {
    return (
      <HashRouter>
        <App>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/">
              <Layout>
                <Switch>
                  <Route exact path="/" render={() => (<Redirect to="/dashboard" />)} />
                  <ProtectRoute path="/dashboard" component={Dashboard} />
                  <ProtectRoute exact path="/users" component={UserList} />
                  <ProtectRoute path="/users/:userID" component={UserDetail} />
                  <ProtectRoute exact path="/posts" component={PostList} />
                  <ProtectRoute path="/posts/:staticURL" component={PostDetail} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </App>
      </HashRouter>
    );
  }
};

class ProtectRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: store.getState().currentUserInfo._id,
    };
  }

  componentDidMount() {
    store.subscribe(() => {
      this.setState({
        _id: store.getState().currentUserInfo._id,
      });
    });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.state._id ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
};