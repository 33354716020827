import React from 'react';
import LoginForm from './component/login-form';

export default class Login extends React.Component {
  render() {
    return (
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content d-flex justify-content-center align-items-center">
            <LoginForm />
          </div>

          <div className="navbar navbar-expand-lg navbar-light">
            <div className="text-center d-lg-none w-100">
              <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                <i className="icon-unfold mr-2"></i> Footer
              </button>
            </div>

            <div className="navbar-collapse collapse" id="navbar-footer">
              <span className="navbar-text">
                &copy; 2018 - 2019. <a href="/">Endless</a> by <a href="/" rel="noopener noreferrer" target="_blank">Ward Chan</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}