import React from 'react';
import { withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as userService from 'service/user';
import initTooltip from './init-tooltip';
import Axios from './component/axios';
import store from 'service/store';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    initTooltip();
    try {
      await userService.initCurrentUserInfo();
    } catch (error) {
      console.log('initCurrentUserInfo', error);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <Provider store={store}>
        <Axios>
          {this.state.isLoading ? (
            'Loading...'
          ) : (
            this.props.children
          )}
        </Axios>
      </Provider>
    );
  }
}

export default withRouter(App);