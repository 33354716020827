import * as actionType from './action-type';

export const postListInfo = (state = {
  totalCount: 0,
  isFetching: false,
  skip: 0,
  list: [],
}, action) => {
  switch (action.type) {
    case actionType.REQUEST_POST_LIST_INFO:
      return {
        ...state,
        isFetching: true,
      };
    case actionType.RECEIVE_POST_LIST_INFO:
      return {
        ...action.postListInfo,
        isFetching: false,
      };
    default:
      return state;
  }
};