import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

export default class Pagination extends React.Component {
  static propTypes = {
    totalCount: PropTypes.number.isRequired,
    rangeStart: PropTypes.number.isRequired,
    rangeEnd: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    pageChanged: PropTypes.func.isRequired,
  }

  render() {
    const totalPage = Math.ceil(this.props.totalCount / this.props.limit);
    return (
      <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
        <span className="mr-auto">Showing {this.props.rangeStart} to {this.props.rangeEnd} of {this.props.totalCount} entries</span>
        <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.props.pageChanged}
          containerClassName={'pagination pagination-sm align-self-center'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          pageLinkClassName={'page-link cursor-pointer'}
          pageClassName={'page-item'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link cursor-pointer'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link cursor-pointer'}
          breakClassName={'page-item disabled'}
          breakLinkClassName={'page-link cursor-pointer'}
        />
      </div>
    );
  }
}