import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom';

class Axios extends React.Component {
  componentDidMount() {
    // init axios config
    axios.defaults.baseURL = config.API_BASE_URL;
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return error;
      }
    );

    axios.interceptors.response.use(
      resp => {
        return resp.data;
      },
      error => {
        const resp = error.response;
        if (resp.status === 401) {
          this.props.history.replace('/login');
        }
        throw resp;
      }
    );
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(Axios);