import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import dayjs from 'dayjs';

import SiteRouter from 'common/router';

// expose global variables
window.$ = window.jQuery = $;
window.dayjs = window.moment = dayjs;

// 用 require 引入的目的是确保以上注入 window 的全局变量能够被使用
require('vendor/limitless');
require('vendor/anytime-picker');

(async () => {
  ReactDOM.render(<SiteRouter />, document.getElementById('root'));
})().catch(error => {
  console.log('root error ==', error);
});