import * as actionType from './action-type';

export const statistic = (state = {
  userTotalCount: 0,
  postTotalCount: 0,
  validSessionTotalCount: 0,
  invalidSessionTotalCount: 0,
  upTime: '',
  isFetching: false,
}, action) => {
  switch (action.type) {
    case actionType.REQUEST_STATISTIC:
      return {
        ...state,
        isFetching: true,
      };
    case actionType.RECEIVE_STATISTIC:
      return {
        ...action.statistic,
        isFetching: false,
      };
    default:
      return state;
  }
};