import axios from 'axios';

import config from 'config';
import store from 'service/store';
import * as action from './action';

export const initCurrentUserInfo = async (option) => {
  const userInfo = await axios.get(config.API.USER_URL, option);
  store.dispatch(action.initCurrentUserInfo(userInfo));
  return userInfo;
};

export const refreshCurrentUserInfo = async (option) => {
  const userInfo = await axios.get(config.API.USER_URL, option);
  store.dispatch(action.refreshCurrentUserInfo(userInfo));
  return userInfo;
};

export const destroyCurrentUserInfo =  async () => {
  await store.dispatch(action.destroyCurrentUserInfo());
};

export const getUserListInfo = async (option) => {
  await store.dispatch(action.requestUserListInfo());
  const userListInfo = await axios.get(config.API.USERS_URL, option);
  userListInfo.skip = option.params.skip || 0;
  await store.dispatch(action.receiveUserListInfo(userListInfo));
  return userListInfo;
};

export const requestDetailUserInfo = async (userID) => {
  const detailUserInfo = await axios.get(`${config.API.USERS_URL}/${userID}`);
  return detailUserInfo;
};

export const updateDetailUserInfo = async (userInfo) => {
  await axios.put(`${config.API.USERS_URL}/${userInfo._id}`, userInfo);
};

export const createDetailUserInfo = async (userInfo) => {
  await axios.post(config.API.USERS_URL, userInfo);
};

export const deleteUser = async (userID) => {
  await axios.delete(`${config.API.USERS_URL}/${userID}`);
};