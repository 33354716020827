import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Card from 'component/card';
import Table from '../component/table';
import Pagination from 'component/pagination';
import * as postService from 'service/post';
import config from 'config';
import * as util from 'common/util';

const mapStateToProps = state => {
  return {
    postListInfo: state.postListInfo,
  };
};

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
    this.pageChanged = this.pageChanged.bind(this);
    this.reload = this.reload.bind(this);
  }

  async componentDidMount() {
    await postService.requestPostListInfo({
      params: {
        limit: config.PAGE_LIMIT,
      },
    });
  }

  deletePost = async (post) => {
    const result = await util.bootbox.confirm({
      title: 'Delete post',
      message: 'Are you sure you want to delete this post?'
    });
    if (result) {
      await postService.deletePost(post._id);
      await this.reload();
    }
  }

  async pageChanged({ selected }) {
    await this.setState({ currentPage: selected + 1 });
    await postService.requestPostListInfo({
      params: {
        limit: config.PAGE_LIMIT,
        skip: (this.state.currentPage - 1) * config.PAGE_LIMIT,
      },
    });
  }

  async reload() {
    await postService.requestPostListInfo({
      params: {
        limit: config.PAGE_LIMIT,
        skip: (this.state.currentPage - 1) * config.PAGE_LIMIT,
      },
    });
  }

  render() {
    const { postListInfo } = this.props;
    const totalCount = postListInfo.totalCount;
    const totalPage = Math.ceil(totalCount / config.PAGE_LIMIT);
    const rangeStart = postListInfo.skip + 1;
    const rangeEnd = postListInfo.skip + (totalPage === this.state.currentPage ? postListInfo.list.length : config.PAGE_LIMIT);
    return (
      <div className="content">
        <Card
          isFetching={postListInfo.isFetching}
          reload={this.reload}
          header={
            <div className="header-elements">
              <h6 className="card-title"><i className="icon-stack mr-1"></i> Post Manager</h6>
              <Link to="/posts/create" className="ml-3 btn btn-sm bg-primary">
                <i className="icon-plus22 mr-1"></i> Create Post
              </Link>
            </div>
          }
          body={<Table deletePost={this.deletePost} postListInfo={this.props.postListInfo} />}
          footer={
            <Pagination
              totalCount={totalCount}
              limit={config.PAGE_LIMIT}
              pageChanged={this.pageChanged}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
            />
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(PostList);