export default () => {
  if (typeof PerfectScrollbar == 'undefined') {
      console.warn('Warning - perfect_scrollbar.min.js is not loaded.')
      return
  }

  new window.PerfectScrollbar('.sidebar-fixed .sidebar-content', {
      wheelSpeed: 2,
      wheelPropagation: true
  })
}