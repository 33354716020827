import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Card from 'component/card';
import * as userService from 'service/user';
import * as util from 'common/util';

const userDetailSchema = Yup.object().shape({
  email: Yup
    .string()
    .email()
    .required(),
  username: Yup
    .string()
    .required(),
  nickname: Yup
    .string()
    .required(),
  password: Yup
    .string()
    .required(),
});

export default class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isEditMode: this.props.match.params.userID !== 'create',
      formData: {
        email: '',
        username: '',
        nickname: '',
        password: '',
        roles: ['admin'],
        status: 'normal'
      },
    };
  }

  async componentDidMount() {
    if (this.state.isEditMode) {
      this.setState({ isFetching: true });
      this.setState({ formData: await userService.requestDetailUserInfo(this.props.match.params.userID) });
      this.setState({ isFetching: false });
    }
  }

  render() {
    return (
      <div className="content">
        <Card
          header={
            <h6 className="card-title">
              <i className="icon-compose mr-1"></i>
              User Detail <span className="mx-1">-</span>
              {this.state.isEditMode ? 'Edit' : 'Create'} User
            </h6>
          }
          className="m-0"
          isFetching={this.state.isFetching}
          body={
            <div className="card-body">
              <Formik
                initialValues={this.state.formData}
                validationSchema={userDetailSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                  try {
                    if (this.state.isEditMode) {
                      await userService.updateDetailUserInfo(values);
                    } else {
                      await userService.createDetailUserInfo(values);
                    }
                    this.props.history.push('/users');
                  } catch (error) {
                    util.handleError(error, { setFieldError });
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form>
                    {this.state.isEditMode &&
                      <div className="form-group row">
                        <div className="col-form-label col-2">User ID</div>
                        <div className="col-10">
                          <div className="form-control-plaintext">{this.state.formData._id}</div>
                        </div>
                      </div>
                    }
                    <div className="form-group row">
                      <div className="col-form-label col-2">E-Mail <span className="text-danger">*</span></div>
                      <div className="col-10">
                        <Field name="email" type="email" className="form-control" placeholder="E-Mail" />
                        <ErrorMessage name="email" component="label" className="validation-invalid-label" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-form-label col-2">Username <span className="text-danger">*</span></div>
                      <div className="col-10">
                        <Field name="username" className="form-control" placeholder="Username" />
                        <ErrorMessage name="username" component="label" className="validation-invalid-label" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-form-label col-2">Nickname <span className="text-danger">*</span></div>
                      <div className="col-10">
                        <Field name="nickname" className="form-control" placeholder="Nickname" />
                        <ErrorMessage name="nickname" component="label" className="validation-invalid-label" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-form-label col-2">Password <span className="text-danger">*</span></div>
                      <div className="col-10">
                        <Field name="password" type="password" className="form-control" placeholder="Password" />
                        <ErrorMessage name="password" component="label" className="validation-invalid-label" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-form-label col-2">Roles <span className="text-danger">*</span></div>
                      <div className="col-10">
                        <Field placeholder="Roles" name="roles" component="select" className="form-control" multiple={true}
                          onChange={e => setFieldValue('roles', [].slice.call(e.target.selectedOptions).map(option => option.value))}
                        >
                          <option value="admin">Admin</option>
                          <option value="editor">Editor</option>
                        </Field>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-form-label col-2">Status</div>
                      <div className="col-10">
                        <Field name="status" component="select" className="custom-select" placeholder="Status">
                          <option value="normal">Normal</option>
                          <option value="archive">Archive</option>
                        </Field>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-right">
                        <Link to="/users" className="btn btn-light mr-2">
                          Back <i className="icon-circle-left2 ml-2"></i>
                        </Link>
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>Submit form <i className="icon-paperplane ml-2"></i></button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          }
        />
      </div>
    );
  }
}