import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import style from './table.module.scss';
import config from 'config';

const statusClassNameMap = {
  published: 'badge-success',
  draft: 'badge-secondary',
  archive: 'badge-danger',
};

export default class Table extends React.Component {
  formatLongDateTime(value) {
    return dayjs(value).format('YYYY-MM-DD HH:mm');
  }

  formatShortDateTime(value) {
    return dayjs(value).format('YYYY-MM-DD');
  }

  deletePost = async (user) => {
    await this.props.deletePost(user);
  }

  render() {
    return (
      <div className="table-response">
        <table className={`table ${style.table}`}>
          <thead>
            <tr className="table-border-double table-active">
              <th style={{ width: '10%' }}>#</th>
              <th>Title</th>
              <th>Tags</th>
              <th>Status</th>
              <th>Author</th>
              <th>Updated At</th>
              <th><i className="icon-menu3"></i></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.postListInfo.list.map((post, index) => (
                <tr key={post._id}>
                  <td>{post._id}</td>
                  <td>
                    <div className="font-weight-semibold">
                      <Link to={`/posts/${post.staticURL}`}>{post.title}</Link>
                    </div>
                    <div className="text-muted">{`${post.type === 'post' ? 'posts/' : ''}${post.staticURL}.html`}</div>
                  </td>
                  <td>
                    {
                      post.tags.length > 0 ? post.tags.map(tag => <span key={tag} className="badge badge-primary badge-pill">{tag}</span>) : '-'
                    }
                  </td>
                  <td>
                    <div className={`badge ${statusClassNameMap[post.status]}`}>
                      {config.FIELD.POST.STATUS[post.status].LABEL}
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light badge-striped badge-striped-left border-pink-400">{post.author.nickname}</div>
                  </td>
                  <td>
                    <abbr
                      data-popup="tooltip"
                      data-placement="bottom"
                      data-html="true"
                      data-original-title={
                        `Created At: ${this.formatLongDateTime(post.createdAt)}
                        <br>
                        Updated At ${this.formatLongDateTime(post.updatedAt)}`
                      }
                    >
                      {this.formatShortDateTime(post.updatedAt)}
                    </abbr>
                  </td>
                  <td>
                    <div className="dropdown">
                      <div className="dropdown-toggle caret-0" data-toggle="dropdown">
                        <i className="icon-menu9"></i>
                      </div>
                      <div className="dropdown-menu dropdown-menu-right">
                        <Link to={`/posts/${post.staticURL}`} className="dropdown-item"><i className="icon-compose"></i> Edit</Link>
                        <span className="dropdown-item" onClick={() => this.deletePost(post)}><i className="icon-trash"></i>Remove</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}