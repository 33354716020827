import 'vendor/limitless/icons/icomoon/styles.min.css';
import 'vendor/limitless/icons/material/styles.min.css';
import 'vendor/limitless/css/bootstrap.css';
import 'vendor/limitless/css/bootstrap_limitless.css';
import 'vendor/limitless/css/layout.css';
import 'vendor/limitless/css/components.css';
import 'vendor/limitless/css/colors.css';

import 'vendor/limitless/js/blockui.min';
import 'vendor/limitless/js/bootstrap.bundle.min';
import PerfectScrollbar from 'vendor/limitless/js/perfect_scrollbar.min'

window.PerfectScrollbar = PerfectScrollbar