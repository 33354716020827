import React from 'react';
import $ from 'jquery';

import * as util from 'common/util';

export default class Card extends React.Component {
  componentDidUpdate() {
    const { isFetching } = this.props;
    if (isFetching) {
      util.blockUI($(this.el));
    } else {
      util.unBlockUI($(this.el));
    }
  }

  componentDidMount() {
    // cardActionReload($(this.el));
    cardActionCollapse($(this.el));
    cardActionRemove($(this.el));
    cardActionFullscreen($(this.el));
  }

  render() {
    return (
      <div className={`card ${this.props.className ? this.props.className : ''}`} ref={el => this.el = el}>
        <div className="card-header bg-transparent header-elements-inline">
          {this.props.header}
          <div className="header-elements">
            <div className="list-icons">
              <span className="list-icons-item" data-action="collapse"></span>
              <span className="list-icons-item" data-action="reload" onClick={this.props.reload}></span>
              <span className="list-icons-item" data-action="remove"></span>
            </div>
          </div>
        </div>
        {this.props.body}
        {this.props.footer &&
          <div className="card-footer">
            {this.props.footer}
          </div>
        }
      </div>
    );
  }
}

/* const cardActionReload = function($el) {
  $el.find('[data-action=reload]:not(.disabled)').on('click', function(e) {
    e.preventDefault();
    const $target = $(this),
      block = $target.closest('.card');

    // Block card
    $(block).block({
      message: '<i class="icon-spinner2 spinner"></i>',
      overlayCSS: {
        backgroundColor: '#fff',
        opacity: 0.8,
        cursor: 'wait',
        'box-shadow': '0 0 0 1px #ddd'
      },
      css: {
        border: 0,
        padding: 0,
        backgroundColor: 'none'
      }
    });

    // For demo purposes
    window.setTimeout(function() {
      $(block).unblock();
    }, 2000);
  });
}; */

const cardActionCollapse = function($el){
  const $cardCollapsedClass = $el.find('.card-collapsed');

  // Hide if collapsed by default
  $cardCollapsedClass.children('.card-header').nextAll().hide();

  // Rotate icon if collapsed by default
  $cardCollapsedClass.find('[data-action=collapse]').addClass('rotate-180');

  // Collapse on click
  $el.find('[data-action=collapse]:not(.disabled)').on('click', function(e) {
    const $target = $(this),
      slidingSpeed = 150;

    e.preventDefault();
    $target.parents('.card').toggleClass('card-collapsed');
    $target.toggleClass('rotate-180');
    $target.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
  });
};

const cardActionRemove = function($el){
  $el.find('[data-action=remove]').on('click', function(e) {
    e.preventDefault();
    const $target = $(this),
      slidingSpeed = 150;

    // If not disabled
    if (!$target.hasClass('disabled')) {
      $target.closest('.card').slideUp({
        duration: slidingSpeed,
        start: function() {
          $target.addClass('d-block');
        },
        complete: function() {
          $target.remove();
        }
      });
    }
  });
};

const cardActionFullscreen = function($el){
  $el.find('[data-action=fullscreen]').on('click', function(e) {
    e.preventDefault();

    // Define vars
    var $target = $(this),
      cardFullscreen = $target.closest('.card'),
      overflowHiddenClass = 'overflow-hidden',
      collapsedClass = 'collapsed-in-fullscreen',
      fullscreenAttr = 'data-fullscreen';

    // Toggle classes on card
    cardFullscreen.toggleClass('fixed-top h-100 rounded-0');

    // Configure
    if (!cardFullscreen.hasClass('fixed-top')) {
      $target.removeAttr(fullscreenAttr);
      cardFullscreen.children('.' + collapsedClass).removeClass('show');
      $('body').removeClass(overflowHiddenClass);
      $target.siblings('[data-action=move], [data-action=remove], [data-action=collapse]').removeClass('d-none');
    }
    else {
      $target.attr(fullscreenAttr, 'active');
      cardFullscreen.removeAttr('style').children('.collapse:not(.show)').addClass('show ' + collapsedClass);
      $('body').addClass(overflowHiddenClass);
      $target.siblings('[data-action=move], [data-action=remove], [data-action=collapse]').addClass('d-none');
    }
  });
};