import * as actionType from './action-type';

export const currentUserInfo = (state = {}, action) => {
  switch (action.type) {
    case actionType.INIT_CURRENT_USER_INFO:
    case actionType.REFRESH_CURRENT_USER_INFO:
      return action.userInfo || state;
    case actionType.DESTROY_CURRENT_USER_INFO:
      return {};
    default:
      return state;
  }
};

export const userListInfo = (state = {
  totalCount: 0,
  isFetching: false,
  skip: 0,
  list: [],
}, action) => {
  switch (action.type) {
    case actionType.REQUEST_USER_LIST_INFO:
      return {
        ...state,
        isFetching: true,
      };
    case actionType.RECEIVE_USER_LIST_INFO:
      return {
        ...action.userListInfo,
        isFetching: false,
      };
    default:
      return state;
  }
};