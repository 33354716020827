import React from 'react'
import PropTypes from 'prop-types'

class Analytics extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
  }

  static defaultProps = {
    config: {},
  }

  componentDidMount() {
    const { analyticsList } = this.props.config

    analyticsList.forEach(analytics => {
      this.requestScript(analytics)
    })
  }

  requestScript(analytics) {
    const { routeMode } = this.props.config
    const eventName = routeMode === 'hash' ? 'hashchange' : 'popstate'

    switch (analytics.type) {
      case 'baidu':
        window._hmt = window._hmt || []
        const scriptEl = document.createElement('script')
        scriptEl.src = `https://hm.baidu.com/hm.js?${analytics.id}`
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(scriptEl, s)

        window.addEventListener(eventName, () => {
          window._hmt.push(['_trackPageview', `${window.location.pathname}${window.location.hash}`])
        })
        break

      case 'google':
        break
    
      default:
        console.warn('unknown type')
        break
    }
    if (analytics === 'baidu') {
    }
  }

  render() {
    return this.props.children || null
  }
}

export default Analytics