import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as sessionService from 'service/session';
import * as userService from 'service/user';

const LoginSchema = Yup.object().shape({
  username: Yup
    .string()
    .required(),
  password: Yup
    .string()
    .required(),
});

const mapStateToProps = state => {
  return {
    userID: state.currentUserInfo._id,
  };
};

class LoginForm extends React.Component {
  render() {
    if (this.props.userID) {
      return <Redirect to="/dashboard" />
    }
    return (
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            await sessionService.login(values);
            await userService.refreshCurrentUserInfo();
            this.props.history.push('/dashboard');
          } catch (error) {
            if (error.status === 422) {
              error.data.errors.forEach(e => setFieldError(e.path, e.message));
            }
          }
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form className="login-form">
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                  <h5 className="mb-0">Login to your account</h5>
                  <span className="d-block text-muted">Enter your credentials below</span>
                </div>

                <div className="form-group form-group-feedback form-group-feedback-left">
                  <Field name="username" className="form-control" placeholder="Username" />
                  <div className="form-control-feedback">
                    <i className="icon-user text-muted"></i>
                  </div>
                  {errors.username && touched.username && <label className="validation-invalid-label">{errors.username}</label>}
                </div>

                <div className="form-group form-group-feedback form-group-feedback-left">
                  <Field type="password" name="password" className="form-control" placeholder="Password" />
                  <div className="form-control-feedback">
                    <i className="icon-lock2 text-muted"></i>
                  </div>
                  {errors.password && touched.password && <label className="validation-invalid-label">{errors.password}</label>}
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Sign in <i className="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div className="text-center">
                  <a href="/">Forgot password?</a>
                </div>
              </div>
            </div>
          </Form>
        )}        
      </Formik>
    );
  }
}

export default withRouter(connect(mapStateToProps)(LoginForm));