import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import Card from 'component/card';
import DatePicker from 'component/date-picker';
import * as postService from 'service/post';
import * as util from 'common/util';
import config from 'config';

const mapStateToProps = state => {
  return {
    currentUserInfo: state.currentUserInfo,
  };
};

const postSchema = Yup.object().shape({
  title: Yup
    .string()
    .required(),
  contentSource: Yup
    .string()
    .required(),
  staticURL: Yup
    .string()
    .required(),
  priority: Yup
    .number()
    .required(),
  tags: Yup
    .string(),
});

class PostDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isEditMode: this.props.match.params.staticURL !== 'create',
      formData: {
        title: '',
        contentSource: '',
        staticURL: '',
        author: '',
        priority: 0,
        status: 'draft',
        type: 'post',
        tags: '',
        createdAt: '',
        updatedAt: '',
      },
    };
  }

  async componentDidMount() {
    if (this.state.isEditMode) {
      this.setState({ isFetching: true });
      const post = await postService.requestPost(this.props.match.params.staticURL);
      this.setState({
        formData: {
          ...post,
          tags: post.tags.join(','),
        },
        isFetching: false,
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Card
          header={
            <h6 className="card-title">
              <i className="icon-compose mr-1"></i>
              Post Detail <span className="mx-1">-</span>
              {this.state.isEditMode ? 'Edit' : 'Create'} Post
            </h6>
          }
          className="m-0"
          body={
            <Formik
              initialValues={this.state.formData}
              validationSchema={postSchema}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                try {
                  const data = {
                    ...values,
                    tags: values.tags ? values.tags.split(',') : [],
                    createdAt: this.state.formData.createdAt === values.createdAt ? '' : dayjs(values.createdAt).toDate().toISOString(),
                    updatedAt: this.state.formData.updatedAt === values.updatedAt ? '' : dayjs(values.updatedAt).toDate().toISOString(),
                  };
                  if (this.state.isEditMode) {
                    await postService.updatePost(data);
                  } else {
                    await postService.createPost(data);
                  }
                  this.props.history.push('/posts');
                } catch (error) {
                  util.handleError(error, { setFieldError });
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
              }) => (
                <div className="card-body">
                  <Form>
                    <div className="row">
                      <div className="col-9">
                        <fieldset>
                          <legend className="font-weight-semibold"><i className="icon-file-text mr-2"></i> Main</legend>
                          <div className="form-group">
                            <label>Title:</label>
                            <Field name="title" className="form-control" placeholder="Enter the title of the post" />
                            <ErrorMessage name="title" component="label" className="validation-invalid-label" />
                          </div>
                          <div className="form-group">
                            <label>Static URL:</label>
                            <Field name="staticURL" className="form-control" placeholder="Enter the title of the post" />
                            <ErrorMessage name="staticURL" component="label" className="validation-invalid-label" />
                          </div>
                          <div className="form-group">
                            <label>Content:</label>
                            <Field name="contentSource" component="textarea" className="form-control" rows="14" cols="5" placeholder="Enter the content of the post" />
                            <ErrorMessage name="contentSource" component="label" className="validation-invalid-label" />
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-3">
                        <fieldset>
                          <legend className="font-weight-semibold"><i className="icon-cogs mr-2"></i> Other</legend>
                          <div className="form-group">
                            <label>Tags:</label>
                            <Field name="tags" className="form-control" placeholder="Enter the tag of the post" />
                            <ErrorMessage name="tags" component="label" className="validation-invalid-label" />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-6">
                                <label>Priority:</label>
                                <Field name="priority" type="number" className="form-control" placeholder="Priority" />
                                <ErrorMessage name="priority" component="label" className="validation-invalid-label" />
                              </div>
                              <div className="col-6">
                                <label>Status:</label>
                                <Field name="status" component="select" className="form-control" placeholder="Status">
                                  {
                                    Object.keys(config.FIELD.POST.STATUS).map(key => (
                                      <option key={key} value={key}>{config.FIELD.POST.STATUS[key].LABEL}</option>
                                    ))
                                  }
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-6">
                                <label>Author:</label>
                                <input type="text" className="form-control" value={this.props.currentUserInfo.nickname} disabled />
                              </div>
                              <div className="col-6">
                                <label>Type:</label>
                                <Field name="type" component="select" className="form-control" placeholder="Type">
                                  {
                                    Object.keys(config.FIELD.POST.TYPE).map(key => (
                                      <option key={key} value={key}>{config.FIELD.POST.TYPE[key].LABEL}</option>
                                    ))
                                  }
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Created At:</label>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button type="button" className="btn btn-light btn-icon">
                                  <i className="icon-calendar3"></i>
                                </button>
                              </span>
                              <DatePicker name="createdAt" value={values.createdAt} onChange={value => setFieldValue('createdAt', value)} />
                            </div>
                            <ErrorMessage name="createdAt" component="label" className="validation-invalid-label" />
                          </div>
                          <div className="form-group">
                            <label>Updated At:</label>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button type="button" className="btn btn-light btn-icon">
                                  <i className="icon-calendar3"></i>
                                </button>
                              </span>
                              <DatePicker name="updatedAt" value={values.updatedAt} onChange={value => setFieldValue('updatedAt', value)} />
                            </div>
                            <ErrorMessage name="updatedAt" component="label" className="validation-invalid-label" />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-9">
                        <div className="text-right">
                          <Link to="/posts" className="btn btn-light mr-2">
                            Back <i className="icon-circle-left2 ml-2"></i>
                          </Link>
                          <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                            Submit form <i className="icon-paperplane ml-2"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(PostDetail);