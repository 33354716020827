import $ from 'jquery';

// Sidebars
// -------------------------

//
// On desktop
//

// Resize main sidebar
var _sidebarMainResize = function () {

  // Flip 2nd level if menu overflows
  // bottom edge of browser window
  var revertBottomMenus = function () {
    $('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function () {
      var $this = $(this),
        navSubmenuClass = 'nav-group-sub',
        navSubmenuReversedClass = 'nav-item-submenu-reversed';

      if ($this.children('.' + navSubmenuClass).length) {
        if (($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
          $this.addClass(navSubmenuReversedClass)
        }
        else {
          $this.removeClass(navSubmenuReversedClass)
        }
      }
    });
  }

  // If sidebar is resized by default
  if ($('body').hasClass('sidebar-xs')) {
    revertBottomMenus();
  }

  // Toggle min sidebar class
  $('.sidebar-main-toggle').on('click', function (e) {
    e.preventDefault();

    $('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main');
    revertBottomMenus();
  });
};

// Toggle main sidebar
var _sidebarMainToggle = function () {
  $(document).on('click', '.sidebar-main-hide', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-main-hidden');
  });
};

// Toggle secondary sidebar
var _sidebarSecondaryToggle = function () {
  $(document).on('click', '.sidebar-secondary-toggle', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-secondary-hidden');
  });
};


// Show right, resize main
var _sidebarRightMainToggle = function () {
  $(document).on('click', '.sidebar-right-main-toggle', function (e) {
    e.preventDefault();

    // Right sidebar visibility
    $('body').toggleClass('sidebar-right-visible');

    // If visible
    if ($('body').hasClass('sidebar-right-visible')) {

      // Make main sidebar mini
      $('body').addClass('sidebar-xs');

      // Hide children lists if they are opened, since sliding animation adds inline CSS
      $('.sidebar-main .nav-sidebar').children('.nav-item').children('.nav-group-sub').css('display', '');
    }
    else {
      $('body').removeClass('sidebar-xs');
    }
  });
};

// Show right, hide main
var _sidebarRightMainHide = function () {
  $(document).on('click', '.sidebar-right-main-hide', function (e) {
    e.preventDefault();

    // Opposite sidebar visibility
    $('body').toggleClass('sidebar-right-visible');

    // If visible
    if ($('body').hasClass('sidebar-right-visible')) {
      $('body').addClass('sidebar-main-hidden');
    }
    else {
      $('body').removeClass('sidebar-main-hidden');
    }
  });
};

// Toggle right sidebar
var _sidebarRightToggle = function () {
  $(document).on('click', '.sidebar-right-toggle', function (e) {
    e.preventDefault();

    $('body').toggleClass('sidebar-right-visible');
  });
};

// Show right, hide secondary
var _sidebarRightSecondaryToggle = function () {
  $(document).on('click', '.sidebar-right-secondary-toggle', function (e) {
    e.preventDefault();

    // Opposite sidebar visibility
    $('body').toggleClass('sidebar-right-visible');

    // If visible
    if ($('body').hasClass('sidebar-right-visible')) {
      $('body').addClass('sidebar-secondary-hidden');
    }
    else {
      $('body').removeClass('sidebar-secondary-hidden');
    }
  });
};


// Toggle content sidebar
var _sidebarComponentToggle = function () {
  $(document).on('click', '.sidebar-component-toggle', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-component-hidden');
  });
};


//
// On mobile
//

// Expand sidebar to full screen on mobile
var _sidebarMobileFullscreen = function () {
  $('.sidebar-mobile-expand').on('click', function (e) {
    e.preventDefault();
    var $sidebar = $(this).parents('.sidebar'),
      sidebarFullscreenClass = 'sidebar-fullscreen'

    if (!$sidebar.hasClass(sidebarFullscreenClass)) {
      $sidebar.addClass(sidebarFullscreenClass);
    }
    else {
      $sidebar.removeClass(sidebarFullscreenClass);
    }
  });
};

// Toggle main sidebar on mobile
var _sidebarMobileMainToggle = function () {
  $('.sidebar-mobile-main-toggle').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-right');

    if ($('.sidebar-main').hasClass('sidebar-fullscreen')) {
      $('.sidebar-main').removeClass('sidebar-fullscreen');
    }
  });
};

// Toggle secondary sidebar on mobile
var _sidebarMobileSecondaryToggle = function () {
  $('.sidebar-mobile-secondary-toggle').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-right');

    // Fullscreen mode
    if ($('.sidebar-secondary').hasClass('sidebar-fullscreen')) {
      $('.sidebar-secondary').removeClass('sidebar-fullscreen');
    }
  });
};

// Toggle right sidebar on mobile
var _sidebarMobileRightToggle = function () {
  $('.sidebar-mobile-right-toggle').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-mobile-right').removeClass('sidebar-mobile-main sidebar-mobile-secondary');

    // Hide sidebar if in fullscreen mode on mobile
    if ($('.sidebar-right').hasClass('sidebar-fullscreen')) {
      $('.sidebar-right').removeClass('sidebar-fullscreen');
    }
  });
};

// Toggle component sidebar on mobile
var _sidebarMobileComponentToggle = function () {
  $('.sidebar-mobile-component-toggle').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('sidebar-mobile-component');
  });
};

export default () => {
  // On desktop
  _sidebarMainResize();
  _sidebarMainToggle();
  _sidebarSecondaryToggle();
  _sidebarRightMainToggle();
  _sidebarRightMainHide();
  _sidebarRightToggle();
  _sidebarRightSecondaryToggle();
  _sidebarComponentToggle();

  // On mobile
  _sidebarMobileFullscreen();
  _sidebarMobileMainToggle();
  _sidebarMobileSecondaryToggle();
  _sidebarMobileRightToggle();
  _sidebarMobileComponentToggle();
};