import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Card from 'component/card';
import Pagination from 'component/pagination';
import * as userService from 'service/user';
import config from 'config';
import * as util from 'common/util';

const statusClassNameMap = {
  normal: 'badge-success',
  archive: 'badge-danger',
};

const mapStateToProps = state => {
  return {
    userListInfo: state.userListInfo,
  };
};

const userRoleMap = config.FIELD.USER.ROLE;
const userStatusMap = config.FIELD.USER.STATUS;

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
    this.pageChanged = this.pageChanged.bind(this);
    this.reload = this.reload.bind(this);
  }

  async removeUser(user) {
    const result = await util.bootbox.confirm({
      title: 'Delete user',
      message: 'Are you sure you want to delete this user?'
    });
    if (result) {
      await userService.deleteUser(user._id);
      await this.reload();
    }
  }

  async componentDidMount() {
    await userService.getUserListInfo({
      params: {
        limit: config.PAGE_LIMIT,
      },
    });
  }

  formatLongDateTime(value) {
    return dayjs(value).format('YYYY-MM-DD HH:mm');
  }

  formatShortDateTime(value) {
    return dayjs(value).format('YYYY-MM-DD');
  }

  async reload() {
    await userService.getUserListInfo({
      params: {
        limit: config.PAGE_LIMIT,
        skip: (this.state.currentPage - 1) * config.PAGE_LIMIT,
      },
    });
  }

  async pageChanged({ selected }) {
    await this.setState({ currentPage: selected + 1 });
    await userService.getUserListInfo({
      params: {
        limit: config.PAGE_LIMIT,
        skip: (this.state.currentPage - 1) * config.PAGE_LIMIT,
      },
    });
  }

  render() {
    const { userListInfo } = this.props;
    const totalCount = userListInfo.totalCount;
    const totalPage = Math.ceil(totalCount / config.PAGE_LIMIT);
    const rangeStart = userListInfo.skip + 1;
    const rangeEnd = userListInfo.skip + (totalPage === this.state.currentPage ? userListInfo.list.length : config.PAGE_LIMIT);
    return (
      <div className="content">
        <Card
          isFetching={userListInfo.isFetching}
          reload={this.reload}
          header={
            <div className="header-elements">
              <h6 className="card-title"><i className="icon-people mr-1"></i> User Manager</h6>
              <Link to="/users/create" className="ml-3 btn btn-sm bg-primary">
                <i className="icon-user-plus mr-1"></i> Create User
              </Link>
            </div>
          }
          body={
            <div className="table-response">
              <table className="table">
                <thead>
                  <tr className="table-border-double table-active">
                    <th style={{'width': '18%'}}>#</th>
                    <th>E-Mail</th>
                    <th>Nickname</th>
                    <th>Roles</th>
                    <th>Status</th>
                    <th>Updated At</th>
                    <th>Last Login At</th>
                    <th><i className="icon-menu3"></i></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    userListInfo.list.map((user, index) => {
                      return (
                        <tr key={user._id}>
                          <td>{user._id}</td>
                          <td>
                            <div className="font-weight-semibold text-primary">{user.email}</div>
                            <div className="text-muted">Username: {user.username}</div>
                          </td>
                          <td>
                            <div className="badge badge-light badge-striped badge-striped-left border-pink-400">{user.nickname}</div>
                          </td>
                          <td>
                            {user.roles.map(role => (<span key={role} className="badge badge-primary badge-pill">{userRoleMap[role].LABEL}</span>))}
                          </td>
                          <td>
                            <div className={`badge ${statusClassNameMap[user.status]}`}>{userStatusMap[user.status].LABEL}</div>
                          </td>
                          <td>
                            <abbr data-popup="tooltip"
                              data-placement="bottom"
                              data-html="true"
                              data-original-title={
                                `Created At: ${this.formatLongDateTime(user.createdAt)}
                                <br>
                                Updated At ${this.formatLongDateTime(user.updatedAt)}`
                              }
                            >
                              {this.formatShortDateTime(user.updatedAt)}
                            </abbr>
                          </td>
                          <td>{user.lastLoginAt ? this.formatLongDateTime(user.lastLoginAt) : '-'}</td>
                          <td>
                            <div className="dropdown">
                              <div className="dropdown-toggle caret-0" data-toggle="dropdown">
                                <i className="icon-menu9"></i>
                              </div>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link to={`/users/${user._id}`} className="dropdown-item"><i className="icon-compose"></i> Edit</Link>
                                <span className="dropdown-item" onClick={this.removeUser.bind(this, user)}><i className="icon-trash"></i>Remove</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          }
          footer={
            <Pagination
              totalCount={totalCount}
              limit={config.PAGE_LIMIT}
              pageChanged={this.pageChanged}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
            />
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(UserList);