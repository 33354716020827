const config = {
  API_BASE_URL: '//api.wardchan.com',
  BAIDU_ANALYTICS_ID: 'fda53886fcbacf24ad2ab9aac0572608',
  API: {
    SESSION_URL: '/session',
    POSTS_URL: '/posts',
    POST_URL: '/post',
    USERS_URL: '/users',
    USER_URL: '/user',
    DASHBOARD_STATISTIC_URL: '/dashboard/statistic',
  },
  FIELD: {
    POST: {
      STATUS: {
        published: {
          LABEL: 'Published',
        },
        draft: {
          LABEL: 'Draft',
        },
        archive: {
          LABEL: 'Archive',
        },
      },
      TYPE: {
        post: {
          LABEL: 'Post',
        },
        simple: {
          LABEL: 'Simple',
        },
      },
    },
    USER: {
      ROLE: {
        admin: {
          LABEL: 'Admin',
        },
        editor: {
          LABEL: 'Editor',
        },
      },
      STATUS: {
        normal: {
          LABEL: 'Normal',
        },
        archive: {
          LABEL: 'Archive',
        },
      },
    },
  },
  PAGE_LIMIT: 8,
};

export default config;