import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';

// import * as sessionReducer from 'service/session/reducer';
import * as userReducer from 'service/user/reducer';
import * as postReducer from 'service/post/reducer';
import * as dashboardReducer from 'service/dashboard/reducer';

const middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

export default createStore(
  combineReducers({
    // ...sessionReducer,
    ...userReducer,
    ...postReducer,
    ...dashboardReducer,
  }),
  applyMiddleware(...middleware),
);