import * as actionType from './action-type';

export const initCurrentUserInfo = userInfo => ({
  type: actionType.INIT_CURRENT_USER_INFO,
  userInfo,
});

export const refreshCurrentUserInfo = userInfo => ({
  type: actionType.REFRESH_CURRENT_USER_INFO,
  userInfo,
});

export const destroyCurrentUserInfo = userInfo => ({
  type: actionType.DESTROY_CURRENT_USER_INFO,
  userInfo,
});

export const requestUserListInfo = () => ({
  type: actionType.REQUEST_USER_LIST_INFO,
});

export const receiveUserListInfo = userListInfo => ({
  type: actionType.RECEIVE_USER_LIST_INFO,
  userListInfo,
});