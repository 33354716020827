import React from 'react';
import { connect } from 'react-redux';

import Card from 'component/card';
import * as dashboardService from 'service/dashboard';

const mapStateToProps = state => {
  return {
    statistic: state.statistic,
  };
};

class Home extends React.Component {

  async componentDidMount() {
    await dashboardService.requestStatistic();
  }

  async reload() {
    await dashboardService.requestStatistic();
  }

  render() {
    const { statistic } = this.props;
    const statisticList = [{
      title: 'Posts',
      iconStyle: 'icon-stack',
      colorStyle: 'border-teal text-teal',
      value: `${statistic.postTotalCount} total`,
    }, {
      title: 'Users',
      iconStyle: 'icon-people',
      colorStyle: 'border-indigo-400 text-indigo-400',
      value: `${statistic.userTotalCount} total`,
    }, {
      title: 'Valid sessions',
      iconStyle: 'icon-popout',
      colorStyle: 'border-warning-400 text-warning-400',
      value: `${statistic.validSessionTotalCount} total`,
    }, {
      title: 'Invalid sessions',
      iconStyle: 'icon-popout',
      colorStyle: 'border-default text-default',
      value: `${statistic.invalidSessionTotalCount} total`,
    }, {
      title: 'Up Time',
      iconStyle: 'icon-watch2',
      colorStyle: 'border-blue text-blue',
      value: statistic.upTime,
    }];
    const statisticsBlockList = statisticList.map(blockConfig => {
      return (
        <div className="col-2" key={blockConfig.title}>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-center mb-2">
              <span className={`btn bg-transparent rounded-round border-2 btn-icon mr-3 ${blockConfig.colorStyle}`}>
                <i className={blockConfig.iconStyle}></i>
              </span>
              <div>
                <div className="font-weight-semibold">{blockConfig.title}</div>
                <div className="text-muted">{blockConfig.value}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="content">
        <div className="row">
          <div className="col-12">
            <Card
              header={<h6 className="card-title">Numerical statistics</h6>}
              isFetching={statistic.isFetching}
              reload={this.reload}
              body={
                (<div className="card-body">
                  <div className="row text-center">
                    {statisticsBlockList}
                  </div>
                </div>)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Home);